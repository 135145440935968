import React from 'react';
import './services.styles.scss';
import { ReactComponent as ConsultingLogo } from '../../assets/svgs/consulting.svg';
import { ReactComponent as GrantWritingLogo } from '../../assets/svgs/grant_writing.svg';
import { ReactComponent as SponsorshipsLogo } from '../../assets/svgs/sponsorships.svg';
import { ReactComponent as FinancingLogo } from '../../assets/svgs/financing.svg';
import { ReactComponent as BusinessPlansLogo } from '../../assets/svgs/businessplans_whitebg.svg';
import { ReactComponent as StrategicPlanningLogo } from '../../assets/svgs/strategicplanning.svg';
import { ReactComponent as GrantsWaitingArtwork } from '../../assets/svgs/grants_waiting.svg';
import { anchorate } from 'anchorate'
import CTADiv from '../../components/cta-div/CTADiv.component';

class ServicesPage extends React.Component {
    componentDidMount(){
        anchorate()
    }
    componentDidUpdate(){
        anchorate()
    }
    render(){
        return (
          <div className="services">
            <div className="clients-hero-section">
              <div className="usable-width">
                <div className="hero-header header-1-title">
                  We Tailor Investment Proposals For Your Success
                </div>
                <div className="services-container">
                  <div className="service-card" id="fiCard">
                    <div className="service-card-logo">
                      <ConsultingLogo />
                    </div>
                    <div
                      id="funding_instructor"
                      className="service-card-header header-3"
                    >
                      Funding Instructor
                    </div>
                    <div className="service-card-description body-text">
                      Our team has over 15 years experience with business
                      development and strategic planning. We understand your
                      needs and are here to forward your vision.
                    </div>
                    <div className="service-card-price body-bold">
                      Price Estimate: $100/hr
                    </div>
                  </div>
                  <div className="service-card" id="gwCard">
                    <div className="service-card-logo">
                      <GrantWritingLogo />
                    </div>
                    <div
                      id="grant_writing"
                      className="service-card-header header-3"
                    >
                      Grant Applications
                    </div>
                    <div className="service-card-description body-text">
                      We will handle your grant applications from inception to
                      completion. We determine the best grant for your project,
                      and handle compilation, grant writing, and submission.
                    </div>
                    <div className="service-card-price body-bold">
                      Price Estimate: $300 - $1000
                    </div>
                  </div>
                  <div className="service-card" id="sponCard">
                    <div className="service-card-logo">
                      <SponsorshipsLogo />
                    </div>
                    <div
                      id="sponsorship_packages"
                      className="service-card-header header-3"
                    >
                      Sponsorship Packages
                    </div>
                    <div className="service-card-description body-text">
                      We put together your profile and curate your application
                      package to ensure your story stands out to investors, and
                      increase your chances of receiving financial support.
                    </div>
                    <div className="service-card-price body-bold">
                      Price Estimate: $150 - $1500
                    </div>
                  </div>
                  <div className="service-card" id="bdpCard">
                    <div className="service-card-logo">
                      <BusinessPlansLogo />
                    </div>
                    <div
                      id="business_development"
                      className="service-card-header header-3"
                    >
                      Business Development Plans
                    </div>
                    <div className="service-card-description body-text">
                      Business development documents include business plans,
                      investment proposals, budget plans and more. Our
                      experienced team is here to help further your business.
                    </div>
                    <div className="service-card-price body-bold">
                      Price Estimate: $700 - $1500
                    </div>
                  </div>
                  <div className="service-card" id="spCard">
                    <div className="service-card-logo">
                      <StrategicPlanningLogo />
                    </div>
                    <div
                      id="strategicplanning"
                      className="service-card-header header-3"
                    >
                      Strategic Planning
                    </div>
                    <div className="service-card-description body-text">
                      We understand fundors and how to make you stand out. We
                      can help hone your application, profile creation,
                      branding, marketing, creative content writing and more.
                    </div>
                    <div className="service-card-price body-bold">
                      Price Estimate: $150 - $1500
                    </div>
                  </div>
                  <div className="service-card" id="finCard">
                    <div className="service-card-logo">
                      <FinancingLogo />
                    </div>
                    <div
                      id="financing"
                      className="service-card-header header-3"
                    >
                      Finance Matching
                    </div>
                    <div className="service-card-description body-text">
                      Whether you’re a business, non-profit artist or student,
                      we put together your profile and seek finance avenues that
                      best match your goals. We find grants that you would
                      qualify for.
                    </div>
                    <div className="service-card-price body-bold">
                      Price Estimate: $500 - $1200
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grants-waiting-section">
              <div className="usable-width">
                <div className="service-container-half service-description">
                  <div className="service-text-div">
                    <div className="service-header header-2">
                      Grants are Waiting for You
                    </div>
                    <div className="service-description body-text">
                      Billions of dollars are given out annually by government
                      agencies and foundations, but most entrepreneurs, artists,
                      NGOs and non-profits don’t even know that they exist, or
                      how to contact them. That’s where we come in! We apply for
                      your grants from trusted organizations.
                    </div>
                  </div>
                </div>
                <div className="service-container-half artwork">
                  <div className="service-artwork">
                    <GrantsWaitingArtwork className="ngo-svg" />
                  </div>
                </div>
              </div>
            </div>

            <CTADiv />
          </div>
        );
    }
}

export default ServicesPage;
