import React from 'react';
import './about.styles.scss';

import {ReactComponent as ConsultingIcon } from '../../assets/svgs/process_consulting.svg';
import {ReactComponent as ResearchIcon } from '../../assets/svgs/process_research.svg';
import {ReactComponent as ApplicationIcon } from '../../assets/svgs/process_application.svg';
import {ReactComponent as ResultsIcon } from '../../assets/svgs/process_results.svg';
import { ReactComponent as ArrowRight } from '../../assets/svgs/carousel-arrow-right.svg';
import { ReactComponent as WhoWeAreArtwork } from '../../assets/svgs/who_we_are_artwork.svg';
import { ReactComponent as HowWeStartedArtwork } from '../../assets/svgs/how_we_started_artwork.svg';
import CityOfTorontoLogo from '../../assets/logos/city_of_toronto.png';
import UMGLogo from '../../assets/logos/Universal_Music_Group.png';
import DigitalMainStreetLogo from '../../assets/logos/digital_main_street.png';
import PlanBGirlLogo from '../../assets/logos/planBgirl.png';
import QAKLogo from '../../assets/logos/quietAsKept.jpg';
import Collapse from 'react-bootstrap/Collapse';
import 'bootstrap/dist/css/bootstrap.min.css';
import Ticker from '../../components/ticker/ticker.component';
import { anchorate } from 'anchorate'
import CTADiv from '../../components/cta-div/CTADiv.component';

class AboutPage extends React.Component {

    constructor(){
        super();
        this.state = {
            open: false,
            learnMoreBtnText: 'Learn More'
        };
    }

    componentDidMount(){
        anchorate()
    }
    componentDidUpdate(){
        anchorate()
    }

    handleCollapseClick(){
        this.setState({ open: !this.state.open,
                        learnMoreBtnText: (this.state.learnMoreBtnText === 'Learn More' ? 'See Less' : 'Learn More')
                    });

    }

    render() {

        return(
            <div className='about'>
                <div className='about-hero-section'>
                    <div className='usable-width'>
                        <div className='hero-header header-1-title'>
                            How We Work
                        </div>
                        <div className='process-section'>
                            <div className='process-step'>
                                <div className='process-step-icon'>
                                    <ConsultingIcon/>
                                </div>
                                <div className='process-step-header body-bold'>
                                    Funding Instructor
                                </div>
                                <Collapse in={this.state.open}>
                                    <div className='process-step-description smaller-text'>
                                        After listening to our client’s needs, we identify the best strategic documents
                                        and services that would assist them in obtaining their grants and funding.
                                        We offer options to our clients to discuss business plans, investment proposals,
                                        sponsorship packages, videography and website development for the success of their project.
                                    </div>
                                </Collapse>
                            </div>

                            <div className='process-arrow'>
                                <ArrowRight/>
                            </div>

                            <div className='process-step'>
                                <div className='process-step-icon'>
                                    <ResearchIcon/>
                                </div>
                                <div className='process-step-header body-bold'>
                                    Research
                                </div>
                                <Collapse in={this.state.open}>
                                    <div className='process-step-description smaller-text'>
                                        We identify the grants and funding available from various agencies that
                                        clients are looking for. Twice a month, there is an updated calendar emailed out
                                        to you to choose the applications you’re interested in applying for. On behalf of our
                                        clients, we also select the appropriate grants and funding that suites their needs.
                                    </div>
                                </Collapse>
                            </div>

                            <div className='process-arrow'>
                                <ArrowRight/>
                            </div>

                            <div className='process-step'>
                                <div className='process-step-icon'>
                                    <ApplicationIcon/>
                                </div>
                                <div className='process-step-header body-bold'>
                                    Application
                                </div>
                                <Collapse in={this.state.open}>
                                    <div className='process-step-description smaller-text'>
                                        Once the application is chosen and the client is ready to apply,
                                        we offer the convenient service of writing their applications,
                                        getting them printed if needed and mailing them out. The client will only
                                        need to cover the invoice, sign the application and sit back to receive the results.
                                    </div>
                                </Collapse>
                            </div>

                            <div className='process-arrow'>
                                <ArrowRight/>
                            </div>

                            <div className='process-step'>
                                <div className='process-step-icon'>
                                    <ResultsIcon/>
                                </div>
                                <div className='process-step-header body-bold'>
                                    Results
                                </div>
                                <Collapse in={this.state.open}>
                                        <div className='process-step-description smaller-text'>
                                            It may take 8 weeks to 4 months before there is any decision made from grant councils
                                            and government agencies on the application. While our clients await the reply, we provide
                                            them with any updates, making sure they receive notice of their results as soon as possible.
                                        </div>
                                </Collapse>
                            </div>
                        </div>
                        <div className="hero-cta" onClick={() => this.handleCollapseClick()}>
                            <a className='btn-secondary-purple-bg'
                                    aria-controls="process-step-description"
                                    aria-expanded={this.state.open}>
                                    {this.state.learnMoreBtnText}
                            </a>
                        </div>
                    </div>
                </div>

                <div className='who-we-are-section' id='who-we-are'>
                    <div className='usable-width'>
                        <div className='service-container-half service-description'>
                            <div className='service-text-div'>
                                <div className='service-header header-1-title'>
                                    Who We Are
                                </div>
                                <div className='service-description body-text'>
                                    <p>
                                        1st Plan is a comprehensive service that helps companies and
                                        individuals get funded! We are a team of consultants, grant writers, and
                                        capital markets experts, with experience in respected funding agencies.
                                    </p>

                                    <p>
                                        Since 2013, we have worked with entrepreneurs, artists, small businesses, NGOs and non-profit
                                        organizations to strengthen their identity and tap into funding opportunities. Every funding expert
                                        on our team knows exactly how to package your application in a way that will resonate with funders.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='service-container-half artwork'>
                            <div className='service-artwork'>
                                <WhoWeAreArtwork className="ngo-svg"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='who-we-are-section' id="how-we-started-section">
                    <div className='usable-width'>
                        <div className='service-container-half'>
                            <div className='service-artwork'>
                                <HowWeStartedArtwork className="artist-svg"/>
                            </div>
                        </div>
                        <div className='service-container-half service-description'>
                            <div className='service-text-div'>
                                <div className='service-header header-1-title'>
                                    How We Started
                                </div>
                                <div className='service-description body-text'>
                                    <p>
                                        When working with Parkdale BIA, Dwayne Williams realised the large
                                        disconnect between available funds and community members who could benefit
                                        from them, if only they knew how to navigate the often mysterious and cumbersome realm of grant funding.
                                    </p>

                                    <p>
                                        With over 15 years experience as a technical writing, publisher and entrepreneur,
                                        Dwayne found himself often helping out his community members with their funding
                                        questions and applications. Wanting to leverage his community by accessing the potential of
                                        funds, Dwayne founded 1st Plan. Since then, his team has grown to be a trusted source of
                                        advice, support, and professionalism to Canadians from all walks of life.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='our-mission-section'>
                    <div className='usable-width'>
                        <div className='our-mission-header body-large'>
                            Our Mission
                        </div>
                        <div className='our-mission-subheader header-2'>
                            To get you the funding you need to succeed
                        </div>
                    </div>
                </div>

                <div className='achievements-section'>
                    <div className='usable-width'>
                        <div className='achievements-header header-1-title'>
                            Achievements
                        </div>
                        <div className='achievements-valuation'>
                            <div className='achievements-milestone'>
                                <div className='achievement-value header-2' data-target="11500">
                                    $<Ticker className="count" end={11500}/>
                                </div>
                                <div className='achievement-source body-bold'>
                                    Artist <br/>Development
                                </div>
                            </div>
                           <div className='achievements-milestone'>
                                <div className='achievement-value header-2'>
                                    $<Ticker className="count" end={40000}/>
                                </div>
                                <div className='achievement-source body-bold'>
                                    Street <br/>Festival
                                </div>
                            </div>
                           <div className='achievements-milestone'>
                                <div className='achievement-value header-2'>
                                    $<Ticker className="count" end={5000}/>
                                </div>
                                <div className='achievement-source body-bold'>
                                    Nightclub <br/>Fundraiser
                                </div>
                            </div>
                           <div className='achievements-milestone'>
                                <div className='achievement-value header-2'>
                                    $<Ticker className="count" end={70000}/>
                                </div>
                                <div className='achievement-source body-bold'>
                                    Community <br/>Fundraiser
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               <div className='community-section' id="community">
                    <div className='usable-width'>
                        <div className='community-header header-1-title'>
                            Community
                        </div>

                        <div className='community-companies'>
                            <div className='community-company'>
                                <img src={DigitalMainStreetLogo} className="community-logo bradius50 blackBg" alt="Digital Main Street" />
                            </div>
                            <div className="empty-div"/>
                            <div className='community-company'>
                                <img src={UMGLogo} className="community-logo" alt="Universal Music Group"/>
                            </div>
                            <div className="empty-div"/>
                            <div className='community-company'>
                                <img src={CityOfTorontoLogo} className="community-logo" alt="City of Toronto"/>
                            </div>
                            <div className="empty-div"/>
                            <div className='community-company'>
                                <img src={PlanBGirlLogo} className="community-logo" alt="Plan B Girl Logo"/>
                            </div>
                            <div className="empty-div"/>
                            <div className='community-company'>
                                <img src={QAKLogo} className="community-logo bradius50" alt="Quiet as Kept Logo"/>
                            </div>
                            <div className="empty-div"/>
                        </div>
                    </div>
                </div>

                <CTADiv />
            </div>
        )
    }
}

export default AboutPage;
