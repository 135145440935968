import React from 'react';
import './contact.styles.scss';
import ContactForm from '../../components/contact-form/contact-form.component';

const ContactPage = () => (
    <div className='contact'>
        <ContactForm header="Leave it to us."
                     subheader="Let's get in touch."/>
    </div>
)

export default ContactPage;