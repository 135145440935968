import React from 'react';
import './contact-form.styles.scss';
import emailjs, { init } from 'emailjs-com';
import { Modal } from 'react-bootstrap';
init("user_douAIrDQ8LIzYzJ5iiMZm"); //EMAILJS INIT

class ContactForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            formAlertIsOpen: false,
            user_name: "",
            email: "",
            message: "",
            errors: []
        }
    }

    openModal = () => this.setState({ formAlertIsOpen: true });
    closeModal = () => this.setState({ formAlertIsOpen: false });
    
    sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_6z5qoyb', 'template_cxfu69a', e.target)
                    .then((result) => {
                        console.log(result.text);
                        this.openModal();
                    }, (error) => {
                        console.log(error.text);
                    });
    }

    componentDidMount(){
        const inputs = document.querySelectorAll(".form-input:required");
        inputs.forEach(input => {
        input.addEventListener(
            "invalid",
            event => {  input.classList.add("error");},
            false
        );
        });
    }


    render(){ 
        return(
                <div className='contact-form-section'>
                    <div className='usable-width'>
                        <div className='contact-form-header header-2'>
                            {this.props.header}
                        </div>
                        <div className='contact-form-subheader body-large'>
                            {this.props.subheader}
                        </div>
                        <form className='contact-form body-bold' 
                              onSubmit={this.sendEmail}>
                            <div className='contact-form-name body-text'>
                                Your Name *
                                <input type="text" className="form-input body-text"
                                       name="user_name" required />
                            </div>
                            <div className='contact-form-email body-text'>
                                Your Email *
                                <input type="email" className="form-input body-text" 
                                       name="user_email"
                                       required/>
                            </div>
                            <div className='contact-form-subject body-text'>
                                Subject
                                <input type="text" className="form-input body-text" name="subject" />
                            </div>
                            <div className='contact-form-budget body-text'>
                                Your Estimated Budget
                                <input type="text" className="form-input" name="user_budget" />
                            </div>
                            <div className='contact-form-message body-text'>
                                Your Message *
                                <textarea className="form-input form-long-message body-text" 
                                          name="message" required />
                            </div>
                            <div className="contact-form-cta">
                                <button className='btn-primary-regular' type="submit" value="Send">Send</button>
                            </div>
                        </form>
                        <Modal show={this.state.formAlertIsOpen}
                               onHide={this.closeModal}>
                            <Modal.Header closeButton className="purple-text">
                            <Modal.Title>Email Sent</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Thank you for your message, we will get back to you soon.</Modal.Body>
                            <Modal.Footer>
                                <button className='btn-primary-regular' type="submit" value="Send" onClick={this.closeModal}>Close</button>
                            </Modal.Footer>
                        </Modal>
                    </div>    
                </div>
        )    
    }        
}

export default ContactForm; 