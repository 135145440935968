import React from 'react';
import './clients.style.scss';
import { ReactComponent as NonProfitOrganizationsArtwork } from '../../assets/svgs/non-profit-org-2x.svg';
import { ReactComponent as ArtistsArtwork } from '../../assets/svgs/artists-2x.svg';
import { ReactComponent as SmallBusinessesArtwork } from '../../assets/svgs/small-business-2x.svg';
import Testimonials from '../../components/testimonials/testimonials.component';
import { anchorate } from 'anchorate'
import CTADiv from '../../components/cta-div/CTADiv.component';

class ClientsPage extends React.Component {
    componentDidMount(){
        anchorate()
    }
    componentDidUpdate(){
        anchorate()
    }
    render(){
        return (
            <div className='clients'>
                <div className='clients-hero-section'>
                    <div className='usable-width'>
                        <div className='hero-header header-1-title'>
                            Our goal is to make funding easier for you.
                        </div>
                        <div className='hero-subheader body-large'>
                            Whether you’re looking to launch your next big project,
                            or just need someone to proofread your scholarship application, we can help.
                        </div>
                    </div>
                </div>

                <div className='service-section' id="ngo-section">
                    <div className='usable-width'>
                        <div className='service-container-half service-description'>
                            <div className='service-text-div'>
                                <div className='service-header header-1-title'>
                                    Non - Profit Organizations
                                </div>
                                <div className='service-description body-text'>
                                    We believe in community, and are driven to help non-profit organizations
                                    fund their important work. Our professional grant writers and consultants
                                    are here to help your organization tap into available government and foundation funds.
                                    We want your business to succeed.
                                </div>
                            </div>
                        </div>
                        <div className='service-container-half artwork'>
                            <div className='service-artwork'>
                                <NonProfitOrganizationsArtwork className="ngo-svg"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='service-section' id="artist-section">
                    <div className='usable-width'>
                        <div className='service-container-half artwork'>
                            <div className='service-artwork'>
                                <ArtistsArtwork className="artist-svg"/>
                            </div>
                        </div>
                        <div className='service-container-half service-description'>
                            <div className='service-text-div'>
                                <div className='service-header header-1-title'>
                                    Artists
                                </div>
                                <div className='service-description body-text'>
                                    Whether it’s a complicated application process,
                                    or you’re just not sure where to look for the right grants,
                                    we can help you secure the funding you need to take your craft
                                    to the next level. We will seek and apply for grants you qualify for,
                                    so you can focus on the work that matters.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='service-section' id="small-business-section">
                    <div className='usable-width'>
                        <div className='service-container-half service-description'>
                            <div className='service-text-div'>
                                <a className='service-header header-1-title'>
                                    Small Businesses
                                </a>
                                <div className='service-description body-text'>
                                    Government funding and small business grants exist to help small
                                    business owners grow, whether you’re starting or scaling up.
                                    To be considered, certain criteria need to be met.
                                    We can help develop your business plan and navigate
                                    the requirements to tap into financing options that will help your business grow.
                                </div>
                            </div>
                        </div>
                        <div className='service-container-half artwork'>
                            <div className='service-artwork'>
                                <SmallBusinessesArtwork className="ngo-svg"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Testimonials />

                <CTADiv/>
            </div>
        )

    }
}
export default ClientsPage;
