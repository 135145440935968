import React, { useState } from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop/scrollToTop.component.js';
import HomePage from './pages/homepage/homepage.component.jsx';
import ServicesPage from './pages/services/services.components.jsx';
import ClientsPage from './pages/clients/clients.component.jsx';
import AboutPage from './pages/about/about.component.jsx';
import ContactPage from './pages/contact/contact.component.jsx';
import Header from './components/header/header.component';
import Footer from './components/footer/footer.component.jsx';
import SchedulingModalContext from './contexts/SchedulingModalContext';
import { PopupModal } from 'react-calendly';


//TODO LIST:
// - on hover, increase scale of community-belief artworks

function App() {
  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false);
  return (
    <SchedulingModalContext.Provider 
      value={{
        setIsSchedulingModalOpen
      }}
      >
      <div>
        <PopupModal
          url={"https://calendly.com/1stplan"}
          onModalClose={() => setIsSchedulingModalOpen(false)}
          open={isSchedulingModalOpen}
          rootElement={document.getElementById("root")}
        />
        <Header />
        <ScrollToTop/>
        <Switch>
          <Route exact path ='/' component={ HomePage } />
          <Route exact path ='/services' component={ ServicesPage }/>
          <Route exact path ='/clients' component={ ClientsPage }/>
          <Route exact path ='/about' component={ AboutPage }/>
          <Route exact path ='/contact' component={ ContactPage }/>
        </Switch>
        <Footer/>
      </div>
    </SchedulingModalContext.Provider>
  );
}

export default App;
