import React from 'react';
import './homepage.styles.scss';
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as LandingArtwork } from './homepage_landing_artwork.svg';
import { ReactComponent as SeekFundingArtwork } from './seek-funding-artwork.svg';
import { ReactComponent as NonProfitOrganizationsArtwork } from '../../assets/svgs/non-profit-organizations.svg';
import { ReactComponent as ArtistsArtwork } from '../../assets/svgs/artists.svg';
import { ReactComponent as SmallBusinessesArtwork } from '../../assets/svgs/small-businesses.svg';
import { ReactComponent as ConsultingLogo } from '../../assets/svgs/consulting.svg';
import { ReactComponent as GrantWritingLogo } from '../../assets/svgs/grant_writing.svg';
import { ReactComponent as SponsorshipsLogo } from '../../assets/svgs/sponsorships.svg';
import { ReactComponent as FinancingLogo } from '../../assets/svgs/financing.svg';
import { ReactComponent as BusinessPlansLogo } from '../../assets/svgs/businessplans.svg';
import { ReactComponent as StrategicPlanningLogo } from '../../assets/svgs/strategicplanning.svg';
import CTADiv from '../../components/cta-div/CTADiv.component';

function HomePage(){
    const history = useHistory();
    return (
      <div className="homepage">
        <div className="homepage-hero">
          <div className="usable-width">
            <div className="service-container-half service-description">
              <div className="service-text-div">
                <div className="service-header header-1-title">
                  Helping Canadians with Business Funding & Growth
                </div>
                <div className="service-description body-large">
                  Get all the assistance you need for grants, investments and
                  business development.
                </div>
                <div className="cta">
                  <Link to="/services" className="btn-secondary-purple-bg">
                    Services
                  </Link>
                </div>
              </div>
            </div>
            <div className="service-container-half artwork">
              <LandingArtwork className="ngo-svg" />
            </div>
          </div>
        </div>

        <div className="focus-craft">
          <div className="usable-width">
            <div className="focus-craft-h">
              <div className="header-3">
                Focus on your craft, leave the rest to us
              </div>
            </div>
            <div className="services-row">
              <div
                className="service-item"
                onClick={() => history.push("/services#fiCard")}
              >
                <ConsultingLogo width="96" height="96" />
                <p>Funding Instructor</p>
              </div>
              <div
                className="service-item"
                onClick={() => history.push("/services#gwCard")}
              >
                <GrantWritingLogo width="96" height="96" />
                <p>Grant Writing</p>
              </div>
              <div
                className="service-item"
                onClick={() => history.push("/services#sponCard")}
              >
                <SponsorshipsLogo width="96" height="96" />
                <p>Sponsorships</p>
              </div>
              <div
                className="service-item"
                onClick={() => history.push("/services#bdpCard")}
              >
                <BusinessPlansLogo width="96" height="96" />
                <p>Business Plans</p>
              </div>
              <div
                className="service-item"
                onClick={() => history.push("/services#spCard")}
              >
                <StrategicPlanningLogo width="96" height="96" />
                <p>Strategic Planning</p>
              </div>
              <div
                className="service-item"
                onClick={() => history.push("/services#finCard")}>
                <FinancingLogo width="96" height="96" />
                <p>Financing</p>
              </div>
            </div>
          </div>
        </div>

        <div className="seek-funding">
          <div className="usable-width">
            <div className="service-container-half service-description">
              <div className="service-text-div">
                <div className="service-header header-2">
                  We Seek Funding For You
                </div>
                <div className="service-description body-text">
                  We work with you to identify financing options and create
                  customized application packages to help you get the funding
                  you need.
                </div>
              </div>
            </div>
            <div className="service-container-half artwork">
              <div className="service-artwork">
                <SeekFundingArtwork className="ngo-svg" />
              </div>
            </div>
          </div>
        </div>

        <div className="community-belief">
          <div className="usable-width">
            <div className="community-belief-header header-3">
              We Believe in Community
            </div>
            <div className="community-belief-description body-text">
              We explore growth opportunities for artists, community groups, and
              organizations seeking investments and believe in the power of
              community spirit. We foster a positive environment for learning,
              developing business, and personal growth of our clients. Let's
              work together.
            </div>
            <div className="community-belief-clients">
              <div
                className="community-belief-client"
                onClick={() => history.push("/clients#ngo-section")}
              >
                <div className="commmunity-belief-client-artwork-div">
                  <div className="community-belief-client-artwork">
                    <NonProfitOrganizationsArtwork className="ngo-artwork" />
                  </div>
                  <div className="community-belief-client-type small-header">
                    Non-Profit Organizations
                  </div>
                </div>
              </div>
              <div
                className="community-belief-client"
                onClick={() => history.push("/clients#artist-section")}
              >
                <div className="commmunity-belief-client-artwork-div">
                  <div className="community-belief-client-artwork">
                    <ArtistsArtwork className="artist-artwork" />
                  </div>
                  <div className="community-belief-client-type small-header">
                    Artists
                  </div>
                </div>
              </div>
              <div
                className="community-belief-client"
                onClick={() => history.push("/clients#small-business-section")}
              >
                <div className="commmunity-belief-client-artwork-div">
                  <div className="community-belief-client-artwork">
                    <SmallBusinessesArtwork className="small-business-artwork" />
                  </div>
                  <div className="community-belief-client-type small-header">
                    Small Businesses
                  </div>
                </div>
              </div>
            </div>
            <div className="community-belief-cta">
              <Link to="/clients" className="btn-secondary-purple-bg">
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <CTADiv />
      </div>
    );
};

export default HomePage;
