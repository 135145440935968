import { useContext } from "react";
import "./CTADiv.styles.scss";
import { Link } from "react-router-dom";
import SchedulingModalContext from "../../contexts/SchedulingModalContext";

function CTADiv(){
    const { setIsSchedulingModalOpen } = useContext(SchedulingModalContext);
    return (
      <div className="get-started">
        <div className="usable-width">
          <div className="get-started-div-header header-2">
            Ready to get started?
          </div>
          <div className="get-started-div-description body-large">
            Let's discuss your needs and see if we're a good fit!
          </div>
          <div className="get-started-actions">
            <Link to="/contact" className="btn-secondary-white-bg">
              Contact
            </Link>

            <a 
              role='button'
              onClick={() => setIsSchedulingModalOpen(true)}
              className="btn-primary-regular"
            >
              Schedule a call
            </a>
          </div>
        </div>
      </div>
    );
}

export default CTADiv;