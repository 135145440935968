import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svgs/company_logo.svg';
import { Nav, Navbar } from "react-bootstrap";
import './header.styles.scss'
import SchedulingModalContext from '../../contexts/SchedulingModalContext';

const Header = () => {
    const { setIsSchedulingModalOpen } = useContext(
      SchedulingModalContext
    );
    const location = useLocation().pathname; 
    return (
      <div className="header">
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          className="usable-width"
        >
          <Navbar.Brand to="/">
            <Link to="/">
              <Logo className="logo d-inline-block align-top" />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav.Link
              className={`option body-bold ${
                location === "/about" ? "current-page" : ""
              }`}
              href="/about"
              eventKey="3"
            >
              About
            </Nav.Link>
            <Nav.Link
              className={`option body-bold ${
                location === "/services" ? "current-page" : ""
              }`}
              href="/services"
              eventKey="1"
            >
              Services
            </Nav.Link>
            <Nav.Link
              className={`option body-bold ${
                location === "/clients" ? "current-page" : ""
              }`}
              href="/clients"
              eventKey="2"
            >
              Clients
            </Nav.Link>
            <Nav.Link
              className={`option body-bold ${
                location === "/about" ? "current-page" : ""
              }`}
              href="/contact"
              eventKey="4"
            >
              Contact
            </Nav.Link>
            <Nav.Link className="option" eventKey="5">
              <button
                onClick={() => setIsSchedulingModalOpen(true)}
                className="btn-primary-header"
              >
                Schedule a call
              </button>
            </Nav.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );        
}

export default Header;