import { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        window.scrollTo(0, 0);
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (null);
}

export default withRouter(ScrollToTop);