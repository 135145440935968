import React from 'react';
import './testimonials.styles.scss';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactComponent as ArrowRight } from '../../assets/svgs/carousel-arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../assets/svgs/carousel-arrow-left.svg';
import { ReactComponent as TestimonialQuote } from '../../assets/svgs/testimonial_quote.svg';

const Testimonials = () => (
    <div className='testimonials'>
        <div className='usable-width'>
            <Carousel nextIcon={<ArrowRight/>}
                      prevIcon={<ArrowLeft/>}>
                <Carousel.Item>
                    <div className='carousel-container'>
                        <div className='testimonial-left-half'>
                            <div className='testimonial-quote-icon'>
                                <TestimonialQuote/>
                            </div>
                            <div className='testimonial-entity smaller-text-bold'>
                                E Sober
                            </div>
                            <div className='testimonial-entity smaller-text'>
                                Artist
                            </div>
                        </div>
                        <div className="empty-div"/>
                        <div className='testimonial-description body-text'>
                            “1st Plan has helped me in various ways such as consultation,
                            assessment and strategic marketing plans that best catered to who
                            I am as an artist and also me skills set in growing and handling my
                            business situations on a day to day basis.”
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carousel-container'>
                        <div className='testimonial-left-half'>
                            <div className='testimonial-quote-icon'>
                                <TestimonialQuote/>
                            </div>
                            <div className='testimonial-entity smaller-text-bold'>
                                Scotian Sparxx
                            </div>
                            <div className='testimonial-entity smaller-text'>
                                Musician
                            </div>
                        </div>
                        <div className="empty-div"/>
                        <div className='testimonial-description body-text'>
                            “Amazing services! Staff that go beyond what is asked/expected of them
                            and really know the direction to point the artist in, to be more effective/efficient.
                            I signed up for the services to help me write grants and ended up switching all my other musical operations
                            over to them including (but not limited to) web site design and advice/strategies on everything from Marketing
                            to how to go about getting radio play. A definite main stay in their industry and field,
                            I suggest them for all business professionals (especially new entrepreneurs) and musicians.”
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carousel-container'>
                        <div className='testimonial-left-half'>
                            <div className='testimonial-quote-icon'>
                                <TestimonialQuote/>
                            </div>
                            <div className='testimonial-entity smaller-text-bold'>
                                KDB
                            </div>
                            <div className='testimonial-entity smaller-text'>
                                Artist
                            </div>
                        </div>
                        <div className="empty-div"/>
                        <div className='testimonial-description body-text'>
                            “Well the universe one day was looking down on me and noticed my struggle,
                            placing 1st Plan in my mist with their services. They gathered the info
                            needed for the grants, that I was best suited for and we started from there.”
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='carousel-container'>
                        <div className='testimonial-left-half'>
                            <div className='testimonial-quote-icon'>
                                <TestimonialQuote/>
                            </div>
                            <div className='testimonial-entity smaller-text-bold'>
                                Amar Kapur
                            </div>
                            <div className='testimonial-entity smaller-text'>
                                CEO
                            </div>
                        </div>
                        <div className="empty-div"/>
                        <div className='testimonial-description body-text'>
                            “My experience with 1st Plan has given me the opportunity to understand
                            that there are other people as passionate about what they do as I am. From understanding
                            the business related industry to music, to analyzing the music and the opportunities
                            available through grants and other opportunities 1st Plan has helped me expand as a
                            musician and an artist. 1st Plan consistently take their time to making
                            sure that each client is as important as the next, making sure that they are in constant
                            communication with where you have come from, where you are at and the direction you are interested in going
                            in and in assisting you to get there. I have nothing but respect for the company and advise
                            their services to anyone looking to take their craft to the next level.”
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    </div>
)

export default Testimonials;
