import React, { useState } from "react";
import './footer.styles.scss';
import { ReactComponent as FirstPlanLogo } from '../../assets/svgs/company_logo_inverse.svg';
import { ReactComponent as FacebookLogo } from '../../assets/icons/facebook-fill.svg';
import { ReactComponent as TwitterLogo } from '../../assets/icons/twitter-fill.svg';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

function Footer(){
    const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
    const handlePrivacyModalClose = () => {
        setIsPrivacyModalOpen(false)
    }
    return (
      <div className="footer">
        <div className="usable-width">
          <div className="footer-left-half">
            <div className="logo-container">
              <FirstPlanLogo />
            </div>
            <div className="business-text-container">
              <div className="business-subtitle smaller-text-bold">
                Funding Applications for Your Business
              </div>
              <div className="business-subtitle-cta smaller-text">
                Let's work together to gain funding and submit grant
                applications for your success.
              </div>
            </div>
            <div className="business-social-links">
              <div className="social-circle-link">
                <a
                  href="https://www.facebook.com/1stplan/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookLogo />
                </a>
              </div>
              <div className="social-circle-link">
                <a
                  href="https://twitter.com/1stplanpro"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterLogo />
                </a>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="footer-right-half">
            <div className="business-sca">
              <div className="business-sca-column">
                <div className="business-sca-column-header smaller-text-bold">
                  <Link to="/services" className="column-link">
                    Services
                  </Link>
                </div>
                <div className="business-sca-column-listItem smaller-text">
                  <Link to="/services#fiCard">
                    <p className="column-link">Funding Instructor</p>
                  </Link>
                  <Link to="/services#gwCard">
                    <p className="column-link">Grant Writing</p>
                  </Link>
                  <Link to="/services#sponCard">
                    <p className="column-link">Sponsorship Packages</p>
                  </Link>
                  <Link to="/services#bdpCard">
                    <p className="column-link">Business Development</p>
                  </Link>
                  <Link to="/services#spCard">
                    <p className="column-link">Strategic Planning</p>
                  </Link>
                  <Link to="/services#finCard">
                    <p className="column-link">Financing</p>
                  </Link>
                </div>
              </div>
              <div className="business-sca-column">
                <div className="business-sca-column-header smaller-text-bold">
                  <Link to="/clients" className="column-link">
                    Clients
                  </Link>
                </div>
                <div className="business-sca-column-listItem smaller-text">
                  <Link to="/clients#ngo-section">
                    <p className="column-link">Non-Profits</p>
                  </Link>
                  <Link to="/clients#artist-section">
                    <p className="column-link">Artists</p>
                  </Link>
                  <Link to="/clients#small-business-section">
                    <p className="column-link">Small Businesses</p>
                  </Link>
                  <Link to="/clients#individuals">
                    <p className="column-link">Individuals</p>
                  </Link>
                  <Link to="/clients#ngo-section">
                    <p className="column-link">NGOs</p>
                  </Link>
                </div>
              </div>
              <div className="business-sca-column">
                <div className="business-sca-column-header smaller-text-bold">
                  <Link to="/about" className="column-link">
                    About
                  </Link>
                </div>
                <div className="business-sca-column-listItem smaller-text">
                  <Link to="/about#about-hero-section">
                    <p className="column-link">How we work</p>
                  </Link>
                  <Link to="/about#who-we-are">
                    <p className="column-link">About Us</p>
                  </Link>
                  <Link to="/about#community">
                    <p className="column-link">Community</p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="copyright-row smaller-text">
              &#9400; 2013 1st Plan Inc. | &nbsp;
              <span
                onClick={() => setIsPrivacyModalOpen(true)}
                className="footerPrivacyPolicy"
              >
                Privacy Policy
              </span>
            </div>
          </div>
          <Modal
            show={isPrivacyModalOpen}
            onHide={handlePrivacyModalClose}
            centered
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>1st Plan Privacy Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "75vh", overflowY: "scroll" }}>
              <p>
                We respect your privacy and are committed to protecting it
                through our compliance with this privacy policy (“Policy”). This
                Policy describes the types of information we may collect from
                you or that you may provide (“Personal Information”) on the
                1stplan.ca website (“Website” or “Service”) and any of its
                related products and services (collectively, “Services”), and
                our practices for collecting, using, maintaining, protecting,
                and disclosing that Personal Information. It also describes the
                choices available to you regarding our use of your Personal
                Information and how you can access and update it.
              </p>
              <p>
                This Policy is a legally binding agreement between you (“User”,
                “you” or “your”) and this Website operator (“Operator”, “we”,
                “us” or “our”). If you are entering into this agreement on
                behalf of a business or other legal entity, you represent that
                you have the authority to bind such entity to this agreement, in
                which case the terms “User”, “you” or “your” shall refer to such
                entity. If you do not have such authority, or if you do not
                agree with the terms of this agreement, you must not accept this
                agreement and may not access and use the Website and Services.
                By accessing and using the Website and Services, you acknowledge
                that you have read, understood, and agree to be bound by the
                terms of this Policy. This Policy does not apply to the
                practices of companies that we do not own or control, or to
                individuals that we do not employ or manage.
              </p>
              <p>
                <strong>Collection of personal information</strong>
              </p>
              <p>
                You can access and use the Website and Services without telling
                us who you are or revealing any information by which someone
                could identify you as a specific, identifiable individual. If,
                however, you wish to use some of the features offered on the
                Website, you may be asked to provide certain Personal
                Information (for example, your name and e- mail address).
              </p>
              <p>
                We receive and store any information you knowingly provide to us
                when you fill any forms on the Website. When required, this
                information may include the following:
              </p>
              <p>
                - Account details (such as user name, unique user ID,
                password,etc) <br />
                - Contact information (such as email address, phone number, etc)
                <br />
                - Basic personal information (such as name, country of
                residence, etc)
                <br />
                - Geolocation data of your device (such as latitude and
                longitude) <br />
                - Any other materials you willingly submit to us (such as
                articles, images, feedback, etc) <br />
              </p>
              <p>
                You can choose not to provide us with your Personal Information,
                but then you may not be able to take advantage of some of the
                features on the Website. Users who are uncertain about what
                information is mandatory are welcome to contact us.
              </p>
              <p>
                <strong>Privacy of children</strong>
              </p>
              <p>
                We do not knowingly collect any Personal Information from
                children under the age of 18. If you are under the age of 18,
                please do not submit any Personal Information through the
                Website and Services. If you have reason to believe that a child
                under the age of 18 has provided Personal Information to us
                through the Website and Services, please contact us to request
                that we delete that child's Personal Information from our
                Services.
              </p>
              <p>
                We encourage parents and legal guardians to monitor their
                children's Internet usage and to help enforce this Policy by
                instructing their children never to provide Personal Information
                through the Website and Services without their permission. We
                also ask that all parents and legal guardians overseeing the
                care of children take the necessary precautions to ensure that
                their children are instructed to never give out Personal
                Information when online without their permission.
              </p>
              <p>
                <strong>Use and processing of collected information</strong>
              </p>
              <p>
                We act as a data controller and a data processor when handling
                Personal Information, unless we have entered into a data
                processing agreement with you in which case you would be the
                data controller and we would be the data processor.
              </p>
              <p>
                Our role may also differ depending on the specific situation
                involving Personal Information. We act in the capacity of a data
                controller when we ask you to submit your Personal Information
                that is necessary to ensure your access and use of the Website
                and Services. In such instances, we are a data controller
                because we determine the purposes and means of the processing of
                Personal Information.
              </p>
              <p>
                We act in the capacity of a data processor in situations when
                you submit Personal Information through the Website and
                Services. We do not own, control, or make decisions about the
                submitted Personal Information, and such Personal Information is
                processed only in accordance with your instructions. In such
                instances, the User providing Personal Information acts as a
                data controller.
              </p>
              <p>
                In order to make the Website and Services available to you, or
                to meet a legal obligation, we may need to collect and use
                certain Personal Information. If you do not provide the
                information that we request, we may not be able to provide you
                with the requested products or services. Any of the information
                we collect from you may be used for the following purposes:
              </p>
              <p>
                - Create and manage user accounts
                <br /> - Send administrative information
                <br /> - Send product and service updates
                <br /> - Respond to inquiries and offer support
                <br /> - Request user feedback
                <br /> - Improve user experience
                <br /> - Enforce terms and conditions and policies
                <br /> - Protect from abuse and malicious users
                <br /> - Respond to legal requests and prevent harm
                <br /> - Run and operate the Website and Services
              </p>
              <p>
                Processing your Personal Information depends on how you interact
                with the Website and Services, where you are located in the
                world and if one of the following applies: (i) you have given
                your consent for one or more specific purposes; (ii) provision
                of information is necessary for the performance of an agreement
                with you and/or for any pre-contractual obligations thereof;
                (iii) processing is necessary for compliance with a legal
                obligation to which you are subject; (iv) processing is related
                to a task that is carried out in the public interest or in the
                exercise of official authority vested in us; (v) processing is
                necessary for the purposes of the legitimate interests pursued
                by us or by a third party.
              </p>
              <p>
                Note that under some legislations we may be allowed to process
                information until you object to such processing by opting out,
                without having to rely on consent or any other of the legal
                bases. In any case, we will be happy to clarify the specific
                legal basis that applies to the processing, and in particular
                whether the provision of Personal Information is a statutory or
                contractual requirement, or a requirement necessary to enter
                into a contract.
              </p>
              <p>
                <strong>Disclosure of information</strong>
              </p>
              <p>
                Depending on the requested Services or as necessary to complete
                any transaction or provide any Service you have requested, we
                may share your information with our affiliates, contracted
                companies, and service providers (collectively, “Service
                Providers”) we rely upon to assist in the operation of the
                Website and Services available to you and whose privacy policies
                are consistent with ours or who agree to abide by our policies
                with respect to Personal Information. We will not share any
                information with unaffiliated third parties.
              </p>
              <p>
                Service Providers are not authorized to use or disclose your
                information except as necessary to perform services on our
                behalf or comply with legal requirements. Service Providers are
                given the information they need only in order to perform their
                designated functions, and we do not authorize them to use or
                disclose any of the provided information for their own marketing
                or other purposes. We will share and disclose your information
                only with the following categories of Service Providers:
              </p>
              <p>
                - Communication and collaboration services
                <br /> - Data analytics services
                <br /> - Data storage services
                <br /> - Product engineering and design services
              </p>
              <p>
                We may also disclose any Personal Information we collect, use or
                receive if required or permitted by law, such as to comply with
                a subpoena or similar legal process, and when we believe in good
                faith that disclosure is necessary to protect our rights,
                protect your safety or the safety of others, investigate fraud,
                or respond to a government request.
              </p>
              <p>
                <strong>Retention of information</strong>
              </p>
              <p>
                We will retain and use your Personal Information for the period
                necessary to comply with our legal obligations, to enforce our
                agreements, resolve disputes, and unless a longer retention
                period is required or permitted by law.
              </p>
              <p>
                We may use any aggregated data derived from or incorporating
                your Personal Information after you update or delete it, but not
                in a manner that would identify you personally. Once the
                retention period expires, Personal Information shall be deleted.
                Therefore, the right to access, the right to erasure, the right
                to rectification, and the right to data portability cannot be
                enforced after the expiration of the retention period.
              </p>
              <p>
                <strong>Do Not Track signals</strong>
              </p>
              <p>
                Some browsers incorporate a Do Not Track feature that signals to
                websites you visit that you do not want to have your online
                activity tracked. Tracking is not the same as using or
                collecting information in connection with a website. For these
                purposes, tracking refers to collecting personally identifiable
                information from consumers who use or visit a website or online
                service as they move across different websites over time. How
                browsers communicate the Do Not Track signal is not yet uniform.
                As a result, the Website and Services are not yet set up to
                interpret or respond to Do Not Track signals communicated by
                your browser. Even so, as described in more detail throughout
                this Policy, we limit our use and collection of your Personal
                Information. For a description of Do Not Track protocols for
                browsers and mobile devices or to learn more about the choices
                available to you, visit internetcookies.com
              </p>
              <p>
                <strong>Social media features</strong>
              </p>
              <p>
                Our Website and Services may include social media features, such
                as the Facebook and Twitter buttons, Share This buttons, etc
                (collectively, “Social Media Features”). These Social Media
                Features may collect your IP address, what page you are visiting
                on our Website and Services, and may set a cookie to enable
                Social Media Features to function properly. Social Media
                Features are hosted either by their respective providers or
                directly on our Website and Services. Your interactions with
                these Social Media Features are governed by the privacy policy
                of their respective providers.
              </p>
              <p>
                <strong>Email marketing</strong>
              </p>
              <p>
                We offer electronic newsletters to which you may voluntarily
                subscribe at any time. We are committed to keeping your e-mail
                address confidential and will not disclose your email address to
                any third parties except as allowed in the information use and
                processing section. We will maintain the information sent via
                e-mail in accordance with applicable laws and regulations.
              </p>
              <p>
                In compliance with the CAN-SPAM Act, all e-mails sent from us
                will clearly state who the e-mail is from and provide clear
                information on how to contact the sender. You may choose to stop
                receiving our newsletter or marketing emails by following the
                unsubscribe instructions included in these emails or by
                contacting us.
              </p>
              <p>
                <strong>Links to other resources</strong>
              </p>
              <p>
                The Website and Services contain links to other resources that
                are not owned or controlled by us. Please be aware that we are
                not responsible for the privacy practices of such other
                resources or third parties. We encourage you to be aware when
                you leave the Website and Services and to read the privacy
                statements of each and every resource that may collect Personal
                Information.
              </p>
              <p>
                <strong>Information security</strong>
              </p>
              <p>
                We secure information you provide on computer servers in a
                controlled, secure environment, protected from unauthorized
                access, use, or disclosure. We maintain reasonable
                administrative, technical, and physical safeguards in an effort
                to protect against unauthorized access, use, modification, and
                disclosure of Personal Information in our control and custody.
                However, no data transmission over the Internet or wireless
                network can be guaranteed.
              </p>
              <p>
                Therefore, while we strive to protect your Personal Information,
                you acknowledge that (i) there are security and privacy
                limitations of the Internet which are beyond our control; (ii)
                the security, integrity, and privacy of any and all information
                and data exchanged between you and the Website and Services
                cannot be guaranteed; and (iii) any such information and data
                may be viewed or tampered with in transit by a third party,
                despite best efforts.
              </p>
              <p>
                <strong>Data breach</strong>
              </p>
              <p>
                In the event we become aware that the security of the Website
                and Services has been compromised or Users’ Personal Information
                has been disclosed to unrelated third parties as a result of
                external activity, including, but not limited to, security
                attacks or fraud, we reserve the right to take reasonably
                appropriate measures, including, but not limited to,
                investigation and reporting, as well as notification to and
                cooperation with law enforcement authorities. In the event of a
                data breach, we will make reasonable efforts to notify affected
                individuals if we believe that there is a reasonable risk of
                harm to the User as a result of the breach or if notice is
                otherwise required by law. When we do, we will send you an
                email, get in touch with you over the phone.
              </p>
              <p>
                <strong>Changes and amendments</strong>
              </p>
              <p>
                We reserve the right to modify this Policy or its terms related
                to the Website and Services at any time at our discretion. When
                we do, we will revise the updated date at the bottom of this
                page, send you an email to notify you. We may also provide
                notice to you in other ways at our discretion, such as through
                the contact information you have provided.
              </p>
              <p>
                An updated version of this Policy will be effective immediately
                upon the posting of the revised Policy unless otherwise
                specified. Your continued use of the Website and Services after
                the effective date of the revised Policy (or such other act
                specified at that time) will constitute your consent to those
                changes. However, we will not, without your consent, use your
                Personal Information in a manner materially different than what
                was stated at the time your Personal Information was collected.
              </p>
              <p>
                <strong>Acceptance of this policy</strong>
              </p>
              <p>
                You acknowledge that you have read this Policy and agree to all
                its terms and conditions. By accessing and using the Website and
                Services and submitting your information you agree to be bound
                by this Policy. If you do not agree to abide by the terms of
                this Policy, you are not authorized to access or use the Website
                and Services. This privacy policy was created with the help of
                WebsitePolicies.com
              </p>
              <p>
                <strong>Contacting us</strong>
              </p>
              <p>
                If you have any questions, concerns, or complaints regarding
                this Policy, the information we hold about you, or if you wish
                to exercise your rights, we encourage you to contact us using
                the details below:
              </p>
              <p>info@1stplan.ca</p>
              <p>
                We will attempt to resolve complaints and disputes and make
                every reasonable effort to honor your wish to exercise your
                rights as quickly as possible and in any event, within the
                timescales provided by applicable data protection laws.
              </p>
              <p>This document was last updated on November 30, 2022</p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn-secondary-white-bg"
                onClick={handlePrivacyModalClose}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
};

export default Footer;
